<template>
  <card-component title="Change Password" icon="lock">
    <form @submit.prevent="submit">
      <b-field label="Current password" message="Required. Your current password">
        <b-input v-model="form.passwordCurrent" name="password_current" type="password" required autcomplete="current-password" />
      </b-field>
      <hr />
      <PasswordStrength v-model="form.password" @isValid="v => (validPassword = v)" />
      <hr />
      <b-field horizontal>
        <div class="control">
          <button type="submit" class="button is-primary" :class="{ 'is-loading': isLoading }" :disabled="!allValid">
            Change Password
          </button>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import PasswordStrength from "@/components/PasswordStrength";

export default {
  name: "PasswordUpdateForm",
  components: {
    CardComponent,
    PasswordStrength
  },
  data() {
    return {
      isLoading: false,
      validPassword: false,
      form: {
        passwordCurrent: null,
        password: null
      }
    };
  },
  computed: {
    allValid() {
      return this.form.passwordCurrent && this.validPassword;
    }
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.$http
        .post("authenticate/changepassword", this.form)
        .then(() => this.$buefy.snackbar.open({ message: "Password changed successfully", queue: false }, 500))
        .catch(e => this.$alerts.showErrorAlert(e, "Error changing password"))
        .then(() => (this.isLoading = false));
    }
  }
};
</script>
